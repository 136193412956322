import React, { useEffect } from 'react';
import { graphql, PageProps } from 'gatsby';
import Section from '@components/Sections/Section';
import track from '@src/tracking/track';
import useSaveUtmParamsToLocalStorage from '@src/hooks/useSaveUtmParamsToLocalStorage';
import { PageQuery, PageQueryVariables } from '../../graphql-types';
import PageWrapper from './shared/PageWrapper';

const Page = ({
  data,
  pageContext,
}: PageProps<PageQuery, PageQueryVariables>) => {
  const { identifier } = data?.page?.tracking ?? {};
  useSaveUtmParamsToLocalStorage();
  useEffect(() => {
    track({
      page: {
        categorySlug: 'landing page',
        pageSlug: data.page?.slug?.localized,
      },
    });
  }, [data.page?.slug?.localized]);
  return (
    <PageWrapper
      locale={pageContext.locale}
      identifier={identifier}
      navigationBar={data?.page?.navigationBar}
      metadata={data?.page?.metadata}
      announcementId={data?.page?.navigationBar?.announcement?._id}
      footerBar={data?.sanityFooterBar}
    >
      {data.page.content.map((sectionContent) => (
        <Section key={sectionContent._key} content={sectionContent} />
      ))}
    </PageWrapper>
  );
};

export const query = graphql`
  query Page($id: String!, $locale: String!, $footerBarId: String!) {
    sanityFooterBar(_id: { eq: $footerBarId }) {
      ...FooterBar
    }
    page: sanityPage(_id: { eq: $id }) {
      _id
      _key
      slug {
        localized(locale: $locale)
      }
      content {
        ...ContentSections
      }
      metadata {
        ...SEO
      }
      tracking {
        ...tracking
      }
      navigationBar {
        ...Header
      }
      redirects
    }
  }
`;

// ts-prune-ignore-next
export default Page;
